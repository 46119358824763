<template>
  <dialogComp
    :title="`发票明细(${billType===1?'申请开票':'已开发票'})`"
    width="640px"
    :isShow="isShow"
    @close="close"
    :isShowFooter="false"
  >
    <tableComp
      :data="tableData"
      :col-configs="theadName"
    >
    </tableComp>
  </dialogComp>
</template>
<script>
import { storeOrderBillDetail } from '@/api'
export default {
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    billType: {
      type: Number,
      default: 1
    },
    info: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      tableData: [],
      theadName: [
        { prop: 'remark', label: '备注' },
        { prop: 'code', label: '发票代码' },
        { prop: 'price', label: '发票金额' },
        { prop: 'billingDate', label: '开票日期' }
      ]
    }
  },
  watch: {
    isShow (val) {
      val && this.getList()
    }
  },
  methods: {
    getList () {
      storeOrderBillDetail({ id: this.info.id, status: this.billType }).then(res => {
        this.tableData = res.data
      })
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>
