<template>
  <dialogComp title="申请开票" :isShow="isShow" @close="close" @sure="sure">
    <el-form :model="formData" :rules="rules" ref="formData" label-width="120px">
      <el-form-item label="可开票金额:">
        {{maxPrice}} <span style="color:#385BFF;cursor:pointer" @click="allPrice">全部金额</span>
      </el-form-item>
        <el-form-item label="申请开票金额:" prop="price">
          <el-input type="number" size="small" placeholder="请输入申请开票金额" v-model="formData.price" @blur="priceBlur"></el-input>
        </el-form-item>
        <inputFormComp
          label="发票备注:"
          prop="remark"
          inputType="textarea"
          :hval="formData.remark"
          @blur="remarkBlur"
          placeholder="请输入发票备注"
        />
    </el-form>
  </dialogComp>
</template>
<script>
import { checkPrice } from '@/utils/common'
import { storeOrderApplyBill } from '@/api'
export default {
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    info: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      formData: {
        price: '',
        remark: ''
      },
      rules: {
        price: [
          { required: true, message: '请输入单价', trigger: 'blur' },
          { validator: checkPrice, trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
    maxPrice () {
      const { price, billedPrice, billingPrice } = this.info
      return (price * 100 - billedPrice * 100 - billingPrice * 100) / 100
    }
  },
  methods: {
    allPrice () {
      this.formData.price = this.maxPrice
    },
    priceBlur () {
      if (this.formData.price > this.maxPrice) {
        this.$message.error(`申请开票金额不能大于${this.maxPrice}`)
        this.formData.price = ''
      }
    },
    remarkBlur (val) {
      this.formData.remark = val
    },
    close () {
      this.$refs.formData.resetFields()
      this.$emit('close')
    },
    sure () {
      this.$refs.formData.validate(valid => {
        if (valid) {
          const { id: orderPaymentStoreId, storeId } = this.info
          const params = {
            orderPaymentStoreId,
            storeId,
            ...this.formData
          }
          storeOrderApplyBill(params).then(_ => {
            this.$message.success('申请成功')
            this.close()
          })
        }
      })
    }
  }
}
</script>
