<template>
    <index v-if="JSON.stringify($route.query) === '{}'" :pageType="pageType" />
    <router-view  v-else/>
</template>
<script>
import index from './comp'
export default {
  components: { index },
  props: ['pageType']
}
</script>
