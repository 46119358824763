<template>
  <div class="layout_common">
    <div class="main_box">
      <div class="main_box_con">
        <div class="search_flex">
          <div class="search_left">
            <inputComp :hval="code" @blur="codeBlur" placeholder="工单编号" />
            <selectComp
              prop="status"
              placeholder="收款状态"
              :data="statusList"
              :hval="status"
              @change="statusChange"
            />
            <merchant v-if="!isStore" :hval="storeName" @storeNameChange="storeNameChange" />
             <dateComp
              :hval="time"
              startPlaceholder="实收开始日期"
              endPlaceholder="实收结束日期"
              @change="timeChange"
            />
            <dateComp
              :hval="settlementTime"
              startPlaceholder="结算开始日期"
              endPlaceholder="结算结束日期"
              @change="settlementTimeChange"
            />
            <btnGroup @search="search" @reset="reset" />
            <btnComp @click="exportData" v-permission="'导出列表'">导出列表</btnComp>
          </div>
        </div>
        <tableComp
          :data="tableData"
          :col-configs="theadName"
          :total="total"
          :currentPage="curPage"
          :pageSize="pageSize"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
          v-loading="loading"
        >
          <el-table-column slot="actualPrice" label="实收金额">
            <template v-slot="scope">
              <div
                v-if="scope.row.actualPrice"
                style="line-height:42px;cursor:pointer;color:#385BFF"
                @click="collectPayDetail(scope.row)"
              >
                {{ scope.row.actualPrice }}
              </div>
              <div v-else> - </div>
            </template>
          </el-table-column>
          <el-table-column slot="billedPrice" label="已开发票">
            <template v-slot="scope">
              <div
                v-if="scope.row.billedPrice"
                style="line-height:42px;cursor:pointer;color:#385BFF"
                @click="billDetail(scope.row,2)"
              >
                {{ scope.row.billedPrice }}
              </div>
              <div v-else> - </div>
            </template>
          </el-table-column>
          <el-table-column slot="billingPrice" label="申请开票">
            <template v-slot="scope">
              <div
                v-if="scope.row.billingPrice"
                style="line-height:42px;cursor:pointer;color:#385BFF"
                @click="billDetail(scope.row,1)"
              >
                {{ scope.row.billingPrice }}
              </div>
              <div v-else> - </div>
            </template>
          </el-table-column>
          <el-table-column slot="cycleDate" label="结算周期" width="190">
            <template v-slot="scope">
              <div>{{scope.row.cycleStartDate}}</div>
              <div>{{scope.row.cycleEndDate}}</div>
            </template>
          </el-table-column>
          <!-- 平台收款页面 -->
          <el-table-column v-if="this.pageType !== 'qc-punish'" slot="num" label="工单数量">
            <template v-slot="scope">
             {{scope.row.orderNum}}
            </template>
          </el-table-column>
          <!-- 品控处罚页面 -->
           <el-table-column v-else slot="num" label="处罚数量/开箱不良">
            <template v-slot="scope">
             {{scope.row.punishCount || '-'}} / {{scope.row.poorUnpackingCount || '-'}}
            </template>
          </el-table-column>
          <el-table-column
            slot="operate"
            label="操作"
            fixed="right"
            width="180"
          >
            <template v-slot="scope">
              <btnComp v-permission="'工单明细'" btnType="text" @click="detail(scope.row)"
                >工单明细</btnComp
              >
              <btnComp v-permission="'申请开票'" v-if="showApplyBill(scope.row.price,scope.row.billedPrice,scope.row.billingPrice)" btnType="text" @click="applyBill(scope.row)"
                >申请开票</btnComp
              >
              <btnComp v-permission="'收款'" v-if="scope.row.status !== 3" btnType="text" @click="collectPay(scope.row)"
                >收款</btnComp
              >
            </template>
          </el-table-column>
          <div slot="statistics" v-if="total > 0">实收金额【 {{dataTotal.actualPrice}} 】 开票金额【 {{dataTotal.billedPrice}} 】</div>
        </tableComp>
      </div>
    </div>
    <!-- 申请开票 -->
    <applyBill :isShow="applyBillShow" @close="close" :info="info" />
    <!-- 发票明细 -->
    <billDetail :isShow="billDetailShow" @close="close" :info="info" :billType="billType" />
    <!-- 收款 -->
    <collectPay :isShow="collectPayShow" @close="close" :info="info" />
    <!-- 收款明细 -->
    <collectPayDetail :isShow="collectPayDetailShow" @close="close"  :info="info" />
  </div>
</template>

<script>
import applyBill from './dialog/applyBill'
import billDetail from './dialog/billDetail'
import collectPay from './dialog/collectPay'
import collectPayDetail from './dialog/collectPayDetail'
import merchant from '@/views/components/merchant'
import { storeOrderPayList, storeOrderPayExport } from '@/api'
import { localGet } from '@/utils/common'

export default {
  components: { applyBill, billDetail, collectPay, collectPayDetail, merchant },
  // 区分是品控处罚还是平台收款
  props: ['pageType'],
  data () {
    return {
      loading: false,
      applyBillShow: false,
      billDetailShow: false,
      collectPayShow: false,
      collectPayDetailShow: false,
      curPage: 1,
      pageSize: 10,
      total: 0,
      info: {},
      code: '',
      billType: 1, // 区分是申请开票1 还是 已开票2
      status: '',
      storeName: '',
      storeId: '',
      date: [],
      time: [],
      startTime: '',
      endTime: '',
      settlementTime: [],
      startSettlementTime: '',
      endSettlementTime: '',
      dataTotal: {}, // 实收金额  开票金额
      statusList: [
        { label: '待收款', value: 1 },
        { label: '部分收款', value: 2 },
        { label: '已收款', value: 3 }
      ],
      tableData: [],
      theadName: [
        { slot: 'operate' },
        { prop: 'identifier', label: 'ID' },
        { prop: 'statusName', label: '收款状态' },
        { prop: 'storeName', label: '商家' },
        { slot: 'cycleDate' },
        { prop: 'price', label: '应收金额' },
        { slot: 'actualPrice' },
        { slot: 'billedPrice' },
        { slot: 'billingPrice' },
        { slot: 'num' }
        // { prop: 'orderNum', label: '工单数量' }
      ]
    }
  },
  computed: {
    // 是否是商家角色
    isStore () {
      return localGet('loginUserInfo').businessRole === 'store'
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    getList () {
      // 1：商家托管费用(数据库默认)，2：商家品控处罚
      this.params = {
        type: this.pageType === 'qc-punish' ? 2 : 1,
        curPage: this.curPage,
        pageSize: this.pageSize,
        code: this.code,
        status: this.status,
        startTime: this.startTime,
        storeId: this.storeId,
        endTime: this.endTime,
        startSettlementTime: this.startSettlementTime,
        endSettlementTime: this.endSettlementTime
      }
      this.loading = true
      storeOrderPayList(this.params).then(res => {
        this.loading = false
        const { list, totalCount, dataTotal } = res.data
        this.tableData = Object.freeze(list)
        this.total = Object.freeze(totalCount)
        this.dataTotal = Object.freeze(dataTotal)
      })
    },
    // 判断实收金额是否等于申请开票加已开发票 是否显示申请开票按钮
    showApplyBill (price, billedPrice, billingPrice) {
      return price * 100 !== (billedPrice * 100 + billingPrice * 100)
    },
    // 导出
    exportData () {
      storeOrderPayExport(this.params)
      this.$message({
        message:
          '已经发起导出任务，请关注个人中心导出任务列表页面，任务完成后可以下载导出文档',
        type: 'warning',
        duration: 5000
      })
    },
    codeBlur (val) {
      this.code = val
    },
    statusChange (val) {
      this.status = val
    },
    storeNameChange (val) {
      this.storeId = val.partnerId
      this.storeName = val.partnerName
    },
    dateChange (startPayTime, endPayTime) {
      this.startPayTime = startPayTime
      this.endPayTime = endPayTime
    },
    settlementTimeChange (startSettlementTime, endSettlementTime) {
      this.startSettlementTime = startSettlementTime
      this.endSettlementTime = endSettlementTime
    },
    timeChange (startTime, endTime) {
      this.startTime = startTime
      this.endTime = endTime
    },
    // 明细
    detail (row) {
      this.$router.push({
        name: this.pageType === 'qc-punish' ? 'qc-punish-detail' : 'store-pay-detail',
        query: {
          id: row.id
        }
      })
    },
    // 申请开票
    applyBill (info) {
      this.info = info
      this.applyBillShow = true
    },
    // 发票详情
    billDetail (info, type) {
      this.info = info
      this.billType = type
      this.billDetailShow = true
    },
    // 收款
    collectPay (info) {
      this.info = info
      this.collectPayShow = true
    },
    // 收款明细
    collectPayDetail (info) {
      this.info = info
      this.collectPayDetailShow = true
    },
    close () {
      this.applyBillShow = false
      this.billDetailShow = false
      this.collectPayShow = false
      this.collectPayDetailShow = false
      this.getList()
    },
    search () {
      this.curPage = 1
      this.getList()
    },
    reset () {
      this.curPage = 1
      this.status = ''
      this.code = ''
      this.storeId = ''
      this.storeName = ''
      this.startSettlementTime = ''
      this.endSettlementTime = ''
      this.settlementTime = []
      this.startTime = ''
      this.endTime = ''
      this.time = []
      this.getList()
    },
    handleSizeChange (val) {
      this.pageSize = val
      this.getList()
    },
    handleCurrentChange (val) {
      this.curPage = val
      this.getList()
    }
  }
}
</script>
