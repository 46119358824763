<template>
  <dialogComp title="确认收款" :isShow="isShow" @close="close" @sure="sure">
    <el-form
      :model="formData"
      :rules="rules"
      ref="formData"
      label-width="100px"
    >
      <el-form-item label="应收金额:">
        {{maxPrice}} <span style="color:#385BFF;cursor:pointer" @click="allPrice">全部金额</span>
      </el-form-item>
      <el-form-item label="实收款金额:" prop="price">
          <el-input type="number" size="small" placeholder="请输入实收款金额" v-model="formData.price" @blur="priceBlur"></el-input>
        </el-form-item>
      <el-form-item label="实收日期:" prop="handleDate">
        <el-date-picker
          v-model="formData.handleDate"
          type="date"
          placeholder="请选择实收日期"
          size="small"
          style="width:100%"
        >
        </el-date-picker>
      </el-form-item>
    </el-form>
  </dialogComp>
</template>
<script>
import { formatDate } from '@/utils/common'
import { storeOrderCollectPay } from '@/api'
export default {
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    info: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      formData: {
        price: '',
        handleDate: ''
      },
      rules: {
        price: [
          { required: true, message: '请输入单价', trigger: 'blur' }
        ],
        handleDate: [
          { required: true, message: '请选择实收日期', trigger: 'change' }
        ]
      }
    }
  },
  computed: {
    maxPrice () {
      const { price, actualPrice } = this.info
      return (price * 100 - actualPrice * 100) / 100
    }
  },
  methods: {
    allPrice () {
      this.formData.price = this.maxPrice
    },
    priceBlur () {
      if (this.formData.price > this.maxPrice) {
        this.$message.error(`申请开票金额不能大于${this.maxPrice}`)
        this.formData.price = ''
      }
    },
    remarkBlur (val) {
      this.formData.remark = val
    },
    close () {
      this.$refs.formData.resetFields()
      this.$emit('close')
    },
    sure () {
      this.$refs.formData.validate(valid => {
        if (valid) {
          const { id: orderPaymentStoreId } = this.info
          const { handleDate, price } = this.formData
          const params = {
            orderPaymentStoreId,
            price,
            handleDate: formatDate(handleDate)
          }
          storeOrderCollectPay(params).then(_ => {
            this.$message.success('收款成功')
            this.close()
          })
        }
      })
    }
  }
}
</script>
