<template>
  <dialogComp
    title="收款明细"
    width="640px"
    :isShow="isShow"
    @close="close"
    :isShowFooter="false"
  >
    <tableComp
      :data="tableData"
      :col-configs="theadName"
    >
    </tableComp>
  </dialogComp>
</template>
<script>
import { storeOrderCollectPayDetail } from '@/api'
export default {
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    info: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      tableData: [],
      theadName: [
        { prop: 'createdTime', label: '操作时间', width: 170 },
        { prop: 'handleDate', label: '实收日期' },
        { prop: 'price', label: '实收金额' },
        { prop: 'payee', label: '收款人' }
      ]
    }
  },
  watch: {
    isShow (val) {
      val && this.getList()
    }
  },
  methods: {
    getList () {
      storeOrderCollectPayDetail({ id: this.info.id }).then(res => {
        this.tableData = res.data
      })
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>
